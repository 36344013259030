
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Sidebar.css'; // Import the external CSS file
import linkedin from '../assets/linkedin3.svg';
import instagram from '../assets/instagram3.svg';
import whatsapp from '../assets/whatsapp3.svg';
import bg8image from '../assets/favicon.svg';
import gmail from '../assets/gmail3.svg';
import { Typography } from '@material-ui/core';

const Sidebar = () => {
  return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link to="/">
          <img src={bg8image} alt="Example" className="sidelogo" />
          </Link>
        </div>
        <div className="sidebar-nav">
        <ul>
          <li>
            <Link to="https://www.linkedin.com/company/emartapp">
              <img src={linkedin} alt="Dashboard" className='icon'/>
            </Link>
            {/* <Typography style={{ fontFamily:'cursive',fontSize: '12px', color: 'white',marginLeft:'6px' }}>linkedIn</Typography> */}

          </li>
          <li>
            <Link to="http://www.instagram.com/emartapp.co">
              <img src={instagram} alt="Products" className='icon'/>
            </Link>
            {/* <Typography style={{ fontFamily:'cursive',fontSize: '12px', color: 'white',marginLeft:'1px' }}>instagram</Typography> */}
          </li>
          <li>
          <Link to="https://wa.me/+16124281616" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="Orders" className='icon'/>
            </Link>
            {/* <Typography style={{ fontFamily:'cursive',fontSize: '12px', color: 'white',marginLeft:'-1px' }}>WhatsApp</Typography> */}
          </li>
          <li>
            <Link to="mailto:contact@emartapp.com">
              <img src={gmail} alt="Customers" className='icon'/>
            </Link>
            {/* <Typography style={{ fontFamily:'cursive',fontSize: '12px', color: 'white',marginLeft:'8px' }}>Mail Us</Typography> */}
          </li>
          
        </ul>
        </div>
        
      </div>
  
  );
};

export default Sidebar;
