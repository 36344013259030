import React, { useState } from 'react';
import Lottie from 'react-lottie';
import 'font-awesome/css/font-awesome.min.css';
import '../styles/Homepage.css';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.svg'
import challenge from '../assets/merit.svg';
import emailjs from 'emailjs-com';
import verified from '../assets/verified.svg';
import simplified from '../assets/simplified.svg';
import { Helmet } from 'react-helmet';
import valuable_market from '../assets/valuable_market.svg';
import bg8image from '../assets/gp10.svg';
import Sidebar from './Sidebar';
import { useEffect, useRef } from 'react';
import image2 from'../assets/updated.svg';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import {  staggerContainer } from '../utils/motion';
import Modal from 'react-modal';
import sucess from '../assets/success.svg';
import Loader from './Loader';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Carousel from './Carousel';
import Hamburger from './Hamburger';
import { db } from '../utils/firebaseConfig';
import earthanimation from '../assets/earth2.json';
import 'react-spring-bottom-sheet/dist/style.css'; 
import { doc, setDoc, collection } from "firebase/firestore"; 



const Homepage = () => {

  const [isCustModal, setIsCustModal] = useState(false);
  const [phoneValidationErr, setPhoneValidationErr] = useState(false);

  const [bottomSheetState, setBottomSheetState] = useState('closed');

  const [bottomSheetPosition, setBottomSheetPosition] = useState('closed');
  const [isDragging, setIsDragging] = useState(false);
  const [change, setChange] = useState(false);
  const initialY = useRef(null);
  let delay = 0;
  let prev = 0;

  const handleSnap = (newPosition) => {
    setBottomSheetPosition(newPosition);
    if (newPosition !== 'closed') {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleNewModel = (Modalstate) => {
    if (Modalstate == 'open') {
      setIsCustModal(true);
    } else {
      setIsCustModal(false);
    }
  }

  const handleMouseUp = () => {
    if (!change) return;
    if (delay === prev) return;

    if (delay >= 0 && bottomSheetPosition === 'closed') {
      handleSnap('semi-half-open');
    } else if (delay > 0 && bottomSheetPosition === 'semi-half-open') {
      handleSnap('half-open');
    } else if (delay > 0 && bottomSheetPosition === 'half-open') {
      handleSnap('semi-fully-open');
    } else if (delay > 0 && bottomSheetPosition === 'semi-fully-open') {
      handleSnap('fully-open');
    } else if (delay < 0 && bottomSheetPosition === 'fully-open') {
      handleSnap('semi-fully-open');
    } else if (delay < 0 && bottomSheetPosition === 'semi-fully-open') {
      handleSnap('half-open');
    } else if (delay < 0 && bottomSheetPosition === 'half-open') {
      handleSnap('semi-half-open');
    } else if (delay < 0 && bottomSheetPosition === 'semi-half-open') {
      handleSnap('closed');
    }

    setChange(false);
  };

  useEffect(() => {
    const handleMouse = (e) => handleMouseUp(e);

    document.addEventListener('mouseup', handleMouse);

    return () => {
      setChange(!change);
      document.removeEventListener('mouseup', handleMouse);
    };
  }, [handleMouseUp]);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // Add more form fields if needed
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = window.innerWidth <= 600;
  const [phone, setPhone] = useState("+91");
  const [loading, setLoading] = useState(true);
  const formRef = useRef(null);

  
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const sendEmail = async (clientEmail, clientName) => {
    // Replace 'YOUR_SERVICE_ID' and 'YOUR_TEMPLATE_ID' with your actual EmailJS service ID and template ID
    const serviceId = 'service_qrncmge';
    const templateId = 'template_6441lya';
    const publickey ='FPEAoz6JK7T9eNwcL';
    const templateParams = {
      to_email: clientEmail,
      client_name: clientName,
      // Add more template variables as needed
    };

    // Send email using EmailJS
    const response = await emailjs.send(serviceId, templateId, templateParams,publickey);

    console.log('Email sent successfully', response);
    // Add any additional actions after sending the email
  };


  const [formSubmitted, setFormSubmitted] = useState(false);

   useEffect(() => {
    if (formSubmitted) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    // Cleanup function to reset the body overflow on component unmount
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [formSubmitted]); 

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (phone.length < 5) {
      setPhoneValidationErr(true);
      return "";
    }
    
    
    setPhoneValidationErr(false);
    // Send email using EmailJS
  try {
    await sendEmail(formData.email, formData.name);
    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error sending email', error);
  }

  try {

    // const userRef = doc(db, 'users', 'UID');
    const userRef = doc(collection(db, 'users'));
    setDoc(userRef, { 
      name: formData.name,
      email: formData.email,
      company: formData.company,
      position: formData.position,
      phone: phone,
     }, { merge: true });

     setIsCustModal(false);
     setFormSubmitted(true);
     
     console.log('Form data added to Firestore successfully');
    } catch (error) {
    setIsCustModal(false);
    alert("Somethig Went Wrong!")
    console.error('Error adding form data to Firestore', error);
  }
  
  if (formRef.current) {
    formRef.current.reset();
  }
};

const resetForm = () => {
  setFormData({
    name: '',
    email: '',
    company: '',
    position: '',
  });
  setPhone('');
};

  const [ref1, inView1] = useInView({
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });

  const [ref4, inView4] = useInView({
    triggerOnce: true,
  });

  const [ref5, inView5] = useInView({
    triggerOnce: true,
  });

  const [ref6, inView6] = useInView({
    triggerOnce: true,
  });

  const [ref7, inView7] = useInView({
    triggerOnce: true,
  });

  const [ref8, inView8] = useInView({
    triggerOnce: true,
  });

  const [ref9, inView9] = useInView({
    triggerOnce: true,
  });

  const [ref10, inView10] = useInView({
    triggerOnce: true,
  });

 
const image2Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image3Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image4Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image5Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image6Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image7Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image8Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image9Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image10Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

const image11Variants = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

useEffect(() => {
  // Simulate an async operation (e.g., fetching data)
  const fetchData = async () => {
    // Your async logic here...

    // Simulate loading completion after 2 seconds
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  fetchData();
}, []); 

if (loading) {
  return (
    <div className="loader-container">
      <Loader />
    </div>
  );
}


  return (
    
    <div className="page">
      {/* ...other elements... */}
      <Sidebar />
      
        <div class="line_wrap">
            <div class="line_item"></div>
            <div class="line_item"></div>
            <div class="line_item"></div>
            <div class="line_item"></div>
            <div class="line_item"></div>
        </div>

      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
      ></motion.div>
  
      <nav className="navbar">
        {/* <button className="button1">Explore</button> */}
        {isMobile && <Hamburger modal={setIsCustModal} />}
        {/* <button className="button2" onClick={() => scrollToSection('join-section')}>
          Join
        </button> */}
      </nav>
      
      <div className="text-container">
        <div className="text-content">
          <Typography variant="h5" gutterBottom align="left">
          <img src={logo} alt="Example" className="logo-1" />
          </Typography>
          <Typography className='subhead'>
              Everything <b>Electronically</b>
          </Typography>
          <p className="additional-sentence" >
          Elevate Your Shopping Experience with Everything Electronically at EmartApp!
          </p>
        </div>
        <motion.div variants={image2Variants} initial="hidden" animate={inView1 ? 'show' : 'hidden'} ref={ref1} className="text-image">
          <img src={image2} alt="Example"  className='image-1'/>
        </motion.div>
      </div>
      

      <div className="text-container1">
      <motion.div variants={image3Variants} initial="hidden" animate={inView2 ? 'show' : 'hidden'} ref={ref2} className="text-image1"
       style={{
    marginLeft: '40px',
  }}>
          <div className="lottie-container">
      <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: earthanimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={550}
      width={600}
      
    />
    </div>
        </motion.div>
        <div className="text-content1">
          <Typography variant="h5" gutterBottom align="left">
          </Typography>
          <Typography variant="body1" style={{fontWeight:'500'}}>
          <b>Introducing eMartApp</b>
          </Typography>
          <p className="additional-sentence1" style={{ textAlign: 'justify' }} >
          Introducing a revolutionary platform tailor-made for verified businesses! Say goodbye to the hassle of going through unreliable and fragmented online
data. Our dynamic electronic marketplace and social networking platform is designed to connect businesses seamlessly, fostering credibility and trust in
every interaction.
          </p>
          <button className="button3"onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button> 
        </div>
      </div>
      {/* <motion.div variants={image6Variants} initial="hidden" animate={inView5 ? 'show' : 'hidden'} ref={ref5} className="">
      {isMobileView && (
        <img src={challenge} alt="Example" className='image-12' />        
    )}
     
      <div className="text-container2"> 
      <div className="text-content2">
          <Typography variant="h5" gutterBottom align="left">
          </Typography>
         <Typography
      variant="body1"
      style={{
        color: 'white',
        fontSize: isMobile ? '24px' : '42px',
      }}
    >
        The <b>Challenge</b>
          </Typography>
          <p className="additional-sentence2" style={{ textAlign: 'justify' }}>
          Finding reliable business information online has become an arduous task, often resulting in an overwhelming array of search results. Countless hours are spent sifting through information, only to encounter instances of deceit and malpractices, making us vulnerable to scams. Navigating through this fragmented and unreliable data prevalent on the internet, is indeed a challenge.
          </p>
          <button className="button3" onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button>
          </div>
          <motion.div variants={image6Variants} initial="hidden" animate={inView5 ? 'show' : 'hidden'} ref={ref5} className="">
        <img src={challenge} alt="Example" className="text-image2" />
         </motion.div>
      </div>
      </motion.div> */}
      <motion.div variants={image5Variants} initial="hidden" animate={inView4 ? 'show' : 'hidden'} ref={ref4} className="">
      
      {isMobileView && (
        
        <img src={verified} alt="Example" className='image-13' />
        
      )}

      
{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">
      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
        Verified<b> Marketplace</b>
      </Typography>
      <p className="additional-sentence2" >
      Interact confidently with businesses that have undergone thorough verification, ensuring trust and authenticity, mitigating the risk of encountering
    fraudulent entities commonly found in traditional online {' '} searches.
      </p>
      <button className="button3" onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
 </motion.div>


{/* <motion.div variants={image7Variants} initial="hidden" animate={inView6 ? 'show' : 'hidden'} ref={ref6} className="">
{isMobileView && (
        <img src={trusted} alt="Example" className='image-13' />
        
      )}
     
{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">
       
      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
        Trusted and Verified<b> Business Network</b>
      </Typography>
      <p className="additional-sentence2">
      Access a trusted network of verified businesses. Engage confidently with credible entities, reduce the risk of encountering fraudulent or unreliable entities commonly found in online searches.
      </p>
      <button className="button3" onClick={() => handleNewModel('open')} ><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
 </motion.div> */}

      {!isMobileView && <Carousel />}
  

      <motion.div variants={image8Variants} initial="hidden" animate={inView7 ? 'show' : 'hidden'} ref={ref7} className="">
    {isMobileView && (
        <img src={simplified} alt="Example" className='image-13' />
        
      )}
      

      
{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">

      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
       Simplified<b> User Experience</b>
      </Typography>
      <p className="additional-sentence22">Connect effortlessly with verified business through our streamlined user interface , eliminating unnecessary complexities , that prioritizes efficiency , making the interactions with businesses more straight forward and enjoyable.</p>
      <button className="button3" onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
</motion.div>

{/* <motion.div variants={image9Variants} initial="hidden" animate={inView8 ? 'show' : 'hidden'} ref={ref8} className="">
{isMobileView && (
        <img src={merit} alt="Example" className='image-13' />
        
      )}
      
{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">
        
      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
      Merit Based<b> Ranking</b>
      </Typography>
      <p className="additional-sentence2">
      Efficiently connect with verified businesses through a streamlined user interface, thereby removing any unnecessary complexities.
      </p>
      <button className="button3" onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
</motion.div> */}

<motion.div variants={image11Variants} initial="hidden" animate={inView10 ? 'show' : 'hidden'} ref={ref10} className="">
    {isMobileView && (
        <img src={challenge} alt="Example" className='image-13' />
        
      )}
      

      
{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">

      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
       Organic<b> Growth</b>
      </Typography>
      <p className="additional-sentence23">
      Foster authentic and sustainable business growth with our platform&#39;s strategic insights, creating organic opportunities. Our merit-based ranking system elevates credible enterprises while reducing visibility of those falling short of expectations.
      </p>
      <button className="button3" onClick={() => handleNewModel('open')}><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
</motion.div>

<motion.div variants={image10Variants} initial="hidden" animate={inView9 ? 'show' : 'hidden'} ref={ref9} className="">
{isMobileView && (
        <img src={valuable_market} alt="Example" className='image-13' />
        
      )}

{isMobileView && (
  <div className="text-container2">
    <div className="text-content2">
      <Typography variant="h5" gutterBottom align="left">
        
      </Typography>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
        }}
      >
     Valuable Market<b> Insights</b>
      </Typography>
      <p className="additional-sentence24">
      Acquire valuable market insights, delve into comprehensive market analysis, explore specific market segments, and receive evaluations that provide
essential information to bolster your strategic business growth plans.
      </p>
      <button className="button3" onClick={() => handleNewModel('open')} ><b>Join Waitlist ➸</b></button>
    </div>
  </div>
)}
</motion.div>

                    <Modal
                         isOpen={formSubmitted}
                         className="modal-container"
                         overlayClassName="overlay"
                         contentLabel="Success Modal"
                     >
        <div>      
          <h2 className="success-message">Thank you for your interest!</h2>
          <p className="success-message-text">
            We will get back to you soon.
          </p>
          
          <img src={sucess} alt="Example" className="sucess-img" />
        </div>
        <button className="sucess-btn" onClick={() => {setFormSubmitted(false);resetForm();}}>Close</button>
      </Modal>
        <div className="handle"></div>

          {!isCustModal ? (
            <div className="custom-bg" onClick={() => handleNewModel('open')}>
              <label htmlFor="join-button" >Join the Waitlist</label>     
            </div>
            
          )
          :
          (
        <div className='cust_model_container'>
            <form className="cust_model_form" onSubmit={handleSubmit} ref={formRef}>
              <div className='cust_model_header'>
                <h4>Join the Waitlist</h4>
                <button class="close-button11" onClick={() => handleNewModel('close')}>❌</button>
              </div> 
    
          <label htmlFor="first-name">Name</label>
          <input
            type="text"
            name="first-name"
            id="first-name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            style={{ paddingLeft: '10px' }}
          />

          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            style={{ paddingLeft: '10px' }}
          />

          <label htmlFor="company">Company</label>
          <input
            type="text"
            name="company"
            id="company"
            value={formData.company}
            onChange={(e) => setFormData({ ...formData, company: e.target.value })}
            required
            style={{ paddingLeft: '10px' }}
          />

          <label htmlFor="position">Position</label>
          <input
            type="text"
            name="position"
            id="position"
            value={formData.position}
            inputStyle={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}
            onChange={(e) => setFormData({ ...formData, position: e.target.value })}
            required
            style={{ paddingLeft: '10px' }}
          />
          <label htmlFor="Phone">Mobile No</label>
              <PhoneInput
                country={"eg"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputStyle={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}
                
                className="custom-phone-input"
              />
              <div style={{position:"relative"}}>
              {phoneValidationErr && (
                <label  className='mobile_err_msg'>Enter a valid Mobile number!</label>
              )}

              <button type="submit" className="cst_btn">
                <span>Join</span>
              </button> 
              </div>
              
       
          </form> 
        </div>
        
        )
      }

      {/* New Form Model End  */}


    <div className="lottie-container1">
      <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: earthanimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={400}
      width={400}
    />
    <Typography
        variant="body1"
        style={{
          color: 'white',
          fontSize: '24px', // Set the font size for mobile view
          textAlign:'center',
          fontFamily:'cursive',
          marginTop:'18px',
          marginBottom:'25px',
          
         
        }}
      >
        Embrace the Digital <b>Revolution</b>
      </Typography>
    </div>



      {/* <div className="text-container6">

      <motion.div variants={image4Variants} initial="hidden" animate={inView3 ? 'show' : 'hidden'} ref={ref3} className="text-image8">
          <img src={bg7image} alt="Example" />
      </motion.div>

       <div className="text-content6">
         <Typography variant="h5" gutterBottom align="left">
         <img src={logo2} alt="Example" className="" />
         </Typography>
         <Typography variant="body1">
         Get the <b>eMartApp</b>
         </Typography>
         <p className="additional-sentence6">
         Explore a world of products, foster relationships with suppliers, and expertly manage and process payments for your orders with the versatile eMartapp. Seamlessly access these capabilities anytime, anywhere, putting you in complete control of your business transactions.
         </p>
         <button className="button3"><b>Download Now</b></button>
       </div>
       
     </div> */}
       {/* <Helmet>
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/65c1dc800ff6374032c9c6ec/1hlukam4c';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet> */}

<footer class="footer-distributed">

      <div class="footer-left">
      <div style={{ display: isMobileView ? 'none' : 'block' }}>
  <p>Contact us</p>
</div>
        <div class="footer-icons111">
          <a href="https://wa.me/+16124281616"><i class="fa fa-whatsapp"></i></a>
          <a href="mailto: contact@emartapp.com"><i class="fa fa-envelope"></i></a>
        </div>
        
      {isMobileView && (
    <img src={bg8image} alt="Mobile" style={{ marginLeft: '5px' }} />
  )}
      </div>

      <div class="footer-center">

      {!isMobileView && (
    <img src={bg8image} alt="Desktop" style={{ marginLeft: '159px' }} />
  )}
  
       
      </div>
      
      <div class="footer-right">

      <div>
          
          <p>Follow Us</p>
          
        </div>
        <div class="footer-icons112">
        <a href="http://www.instagram.com/emartapp.co"><i class="fa fa-instagram"></i></a>
        <a href="https://www.linkedin.com/company/emartapp"><i class="fa fa-linkedin"></i></a>
        </div>
        <br></br>

        <div style={{ display: isMobileView ? 'block' : 'none' }}>
     <p>Contact Us</p>
   </div>
 
       <div className="footer-icons114" style={{ display: isMobileView ? 'block' : 'none' }}>
      <a href="https://wa.me/+16124281616"><i className="fa fa-whatsapp"></i></a>
     <a href="mailto: contact@emartapp.com"><i className="fa fa-envelope"></i></a>
</div>

        {/* <div class="footer-icons">
          <a href="http://www.instagram.com/emartapp.co"><i class="fa fa-instagram"></i></a>
          <a href="https://wa.me/+16124281616"><i class="fa fa-whatsapp"></i></a>
          <a href="https://www.linkedin.com/company/emartapp"><i class="fa fa-linkedin"></i></a>
          <a href="mailto: contact@emartapp.com"><i class="fa fa-envelope"></i></a>
        </div> */}
      </div>
    </footer>
    </div>  
  );
};
export default Homepage;  