import React, { useRef } from 'react';
import { useReducer, useEffect } from 'react';
import './style.css';

console.clear();
const descriptionStyle = {
  textAlign: 'justify',
  letterSpacing: '0.02em',
  fontSize:'14px',
  marginRight: '19px'
};
    const slides = [
      {
        title: "Verified",
        subtitle: "Marketplace",
        description: "Interact confidently with businesses that have undergone thorough verification, ensuring trust and authenticity, mitigating the risk of encountering fraudulent entities commonly found in traditional online searches.",
        image:"/images/verified.jpg"
      },
      {
        title: "Simplified ",
        subtitle: "User Experience",
        description: "Connect effortlessly with verified businesses through our streamlined user interface, eliminating unnecessary complexities, that prioritizes efficiency, making the interactions with businesses more straight forward and enjoyable.",
        image:"/images/simplified.jpg"
      },
      // {
      //   title: "Trusted and Verified",
      //   subtitle: "Business Network",
      //   description: "Access a trusted network of verified businesses. Engage confidently with credible entities, reduce the risk of encountering fraudulent or unreliable entities commonly found in online searches.",
      //   image:"/images/trusted.jpg"
         
      // },
      {
        title: "Valuable Market",
        subtitle: "Insights",
        description: "Acquire valuable market insights, delve into comprehensive market analysis, explore specific market segments, and receive evaluations that provide essential information to bolster your strategic business growth plans.",
        image:
        "/images/market.jpg"
      },
      {
        title: "Organic Growth",
        subtitle: "Opportunities",
        description: "Foster authentic and sustainable business growth with our platform's strategic insights, creating organic opportunities. Our merit-based ranking system elevates credible enterprises while reducing visibility of those falling short of expectations.",
        image:"/images/organic.jpg"
      },
      // {
      //   title: "Merit Based",
      //   subtitle: "Ranking",
      //   description: "Straightforward approach yet impactful. Every registered enterprise prioritizes perfecting it’s on ground presence, ensuring credibility and reliability. Merit-based ranking system automatically promotes reliable entities while reducing the visibility of those failing to meet expectations.",
      //   image:"/images/merit.jpg"
      // },
      // {
      //   title: "Community of",
      //   subtitle: "Reliability",
      //   description: "Cultivate a merit-based environment where businesses are encouraged to maintain reliability, fostering a community-driven by accountability, trust, and quality interactions.",
      //   image:
      //   "/images/community.jpg"
      // },
      // {
      //   title: "Continuous Platform ",
      //   subtitle: "Enhancement",
      //   description: "Subscribers enjoy continuous platform enhancements and dedicated support. The platform undergoes constant improvements, ensuring access to the latest tools and continual assistance for a seamless user ",
      //   image:"/images/platform.jpg"
         
      // }
    ];
    
    function useTilt(active) {
      const ref = React.useRef(null);
    
      React.useEffect(() => {
        if (!ref.current || !active) {
          return;
        }
    
        const state = {
          rect: undefined,
          mouseX: undefined,
          mouseY: undefined
        };
    
        let el = ref.current;
    
        const handleMouseMove = (e) => {
          if (!el) {
            return;
          }
          if (!state.rect) {
            state.rect = el.getBoundingClientRect();
          }
          state.mouseX = e.clientX;
          state.mouseY = e.clientY;
          const px = (state.mouseX - state.rect.left) / state.rect.width;
          const py = (state.mouseY - state.rect.top) / state.rect.height;
    
          el.style.setProperty("--px", px);
          el.style.setProperty("--py", py);
        };
    
        el.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          el.removeEventListener("mousemove", handleMouseMove);
        };
      }, [active]);
    
      return ref;
    }
    
    const initialState = {
      slideIndex: 0
    };
    
    const slidesReducer = (state, event) => {
      if (event.type === "NEXT") {
        return {
          ...state,
          slideIndex: (state.slideIndex + 1) % slides.length
        };
      }
      if (event.type === "PREV") {
        return {
          ...state,
          slideIndex:
            state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1
        };
      }
    };
    
    function Slide({ slide, offset }) {
      const active = offset === 0 ? true : null;
      const ref = useTilt(active);

      const imageStyle = {
        backgroundImage: `url('${process.env.PUBLIC_URL}${slide.image}')`
      };
    
      return (
        <div
          ref={ref}
          className="slide"
          data-active={active}
          style={{
            "--offset": offset,
            "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
            
          }}
        >
          {/* <div
            className="slideBackground"
            style={{
              backgroundImage: `url('${slide.image}')`
            }}
          /> */}
          <div
            className="slideContent"
            style={imageStyle}
          >
            <div className="slideContentInner">
              <h2 className="slideTitle">{slide.title}</h2>
              <h3 className="slideSubtitle">{slide.subtitle}</h3>
              <p className="slideDescription" style={descriptionStyle}>{slide.description}</p>
            </div>
          </div>
        </div>
      );
    }

    function Carousel() {
      const [state, dispatch] = React.useReducer(slidesReducer, initialState);

      useEffect(() => {
        const interval = setInterval(() => {
          dispatch({ type: 'NEXT' });
        }, 12000); // Change the interval (in milliseconds) as needed
    
        return () => clearInterval(interval);
      }, []); // Empty dependency array to run the effect only once
    
      return (
        <div className="slides_container">
        <div className="slides">
          <button onClick={() => dispatch({ type: "PREV" })}>‹</button>
    
          {[...slides, ...slides, ...slides].map((slide, i) => {
            let offset = slides.length + (state.slideIndex - i);
            return <Slide slide={slide} offset={offset} key={i} />;
          })}
          <button onClick={() => dispatch({ type: "NEXT" })}>›</button>
        </div>
        </div>
      );
    }
    

    

export default Carousel;
