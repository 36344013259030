import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import Sidebar from './components/Sidebar';
import ReactGA from 'react-ga'; // or 'react-ga4' if using GA4
import emailjs from 'emailjs-com';


emailjs.init('FPEAoz6JK7T9eNwcL');
function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} /> // Default route for homepage
        <Route path="/homepage" element={<Homepage />} /> // Additional route for /homepage
      </Routes>
    </Router>
);
}

export default App;
