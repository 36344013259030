// src/utils/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTwUO0GS1egXdXVyuZ90La7YnDaRZ4WzI",
  authDomain: "e-mart-285d9.firebaseapp.com",
  projectId: "e-mart-285d9",
  storageBucket: "e-mart-285d9.appspot.com",
  messagingSenderId: "912198340655",
  appId: "1:912198340655:web:8d7ba537a25ddccb0db6da",
  measurementId: "G-VN1FWWHW38"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export Firestore instance
const db = getFirestore(app);

export { db };
